import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import StatusBadge from '@/components/Badge/StatusBadge.vue';
import HxPdfExporter from '@/components/HxPdfExporter/HxPdfExporter.vue';
import Modal from '@/components/Modal/Modal.vue';

import TransferService from '@/services/API/transfer.service';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import InvoiceService from '@/services/API/invoice.service';
import socketClient from '@/services/SOCKET';
import AddCostDetailService from '@/services/API/add_cost_details.service';

import CommonConst from '@/constants/CommonConst';

import moment from 'moment';
import Axios from 'axios';
import { mapState } from 'vuex';

const SELECTIVE_CONTENT =
	'クリエイターより \n「ご請求書 」確定依頼が届いています \n確定しますか？';
const PLAIN_NOTI_CONTENTS =
	'クリエイターに請求承認を通知しました。\n次の工程に進みます';
const NO_DATA_TEXT = '作成中';
const PAYMENT_TEXT =
	'クリエイターより\n「 御請求書 」が届いています\n期日までにお支払いください';

export default {
	name: 'SettlementScreen',
	components: {
		ScreenHeader,
		ProjectDeadline,
		NotificationModal,

		HxPdfExporter,
		Modal,
		StatusBadge,
	},
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			screenName: '請求書作成',
			defaultFileName: '',
			selectiveContents: SELECTIVE_CONTENT,
			plainContents: '',
			requirementContents: '',
			companyName: '',
			directLink: 'finish',
			estimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			estimateParticularId: undefined,
			isConfirmMessShow: false,
			isQuotationShow: false,
			pdfData: {},
			addCostDetails: [],
			// Data using for get image and pdf file url
			pdfFileKey: '',
			pdfImageKey: '',
			defaultFilePath: '/pdf/template.jpeg',
			pdfImageUrl: '',
			pdfFileUrl: '',
			selectiveModalId: 'estimate-selective-modal',
			plainModalId: 'estimate-plain-modal',
			invoiceData: {},
			noneObjectLabel: '',
			noDataText: NO_DATA_TEXT,
			paymentText: PAYMENT_TEXT,
			isShowContent: false,
			settlementStatus: '',
			downloadButtonShow: false,
			mailContent: '',
			isSearchIconShow: false,

			pdfPreviewer: {},
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'redDotIndexs',
		]),
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.destinations,
				sender: this.managementMasterInfo['sender_email_address'],
				subject:
					'【COMODE】' +
					this.projectInfo['project_name'] +
					'案件_' +
					this.screenName +
					'のお知らせ',
				template: 'processmail',
				template_params: {
					projectName: this.projectInfo['project_name'],
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: '',
					managerCompanyName: this.managementMasterInfo['company_name'],
					managerMail: this.managementMasterInfo['footer_email_address'],
				},
			};
		},
	},

	watch: {
		// Calculate amount when consumption_tax_rate and addcostDetails existed
		managementMasterInfo(newVal) {
			if (newVal['consumption_tax_rate'] && this.addCostDetails.length > 0) {
				this.computeAddCostDetailsMoneyAmount(
					newVal['consumption_tax_rate'],
					this.addCostDetails
				);
			}
		},
	},

	methods: {
		onClose() {
			this.$emit('on-close-settlement-screen');
		},
		/**
		 * Calculate subtotal of all addCostDetail records
		 * @param {Array} addCostDetails AddCostDetail array
		 * @returns addCostDetailsSubtotal
		 */
		addCostDetailsSubtotal(addCostDetails) {
			if (addCostDetails && addCostDetails.length > 0) {
				return addCostDetails.reduce((acc, addCostDetail) => {
					return (
						acc + Math.trunc(addCostDetail['quantity'] * addCostDetail['unit_price'])
					);
				}, 0);
			}
		},

		removeSettlementRedDot() {
			const redDotIndexs = this.removeScreenId(
				this.redDotIndexs,
				CommonConst.SCREEN_ID.SETTLEMENT
			);
			this.$store.commit('setRedDotIndexs', redDotIndexs);
		},

		/**
		 *
		 * @param {Array} redDotIndexs
		 * @param {Integer} removingScreenId
		 * @return {Array} Array after remove screenId
		 */
		removeScreenId(redDotIndexs, removingScreenId) {
			return redDotIndexs.filter((screenId) => screenId !== removingScreenId);
		},

		/**
		 * Calculate Addcost Details money amount
		 * @param {Integer} consumptionTaxRate ManagementMaster consumption tax rate
		 * @param {Array } addCostDetails AddCostDetail array
		 */
		computeAddCostDetailsMoneyAmount(consumptionTaxRate, addCostDetails) {
			if (consumptionTaxRate && addCostDetails.length > 0) {
				let addCostDetailsSubtotal = this.addCostDetailsSubtotal(addCostDetails);

				let addCostDetailsConsumptionTax =
					(addCostDetailsSubtotal * consumptionTaxRate) / 100;
				let subtotal =
					this.estimateData.estimateParticular['subtotal'] + addCostDetailsSubtotal;
				let consumptionTax =
					this.estimateData.estimateParticular['consumption_tax'] +
					addCostDetailsConsumptionTax;
				this.estimateData.estimateParticular['subtotal'] = subtotal;
				let holdingTax = 0;
				if (this.projectInfo.with_holding_tax == 1) {
					if (subtotal < 1000000) {
						holdingTax = (subtotal * 10.21) / 100;
					} else {
						holdingTax = ((subtotal - 1000000) * 20.42) / 100 + 102100;
					}
				}
				holdingTax = Math.trunc(holdingTax);
				this.estimateData.estimateParticular['subtotal'] = subtotal;
				this.estimateData.estimateParticular['consumption_tax'] = consumptionTax;
				this.estimateData.estimateParticular['total'] = subtotal + consumptionTax;
				+holdingTax;
			}
		},

		async onDownload() {
			this.$store.commit('setIsAppProcessing', true);

			if (!this.pdfFileUrl) {
				this.$store.commit('setIsAppProcessing', false);
				return;
			}

			this._getDefaultFileName(this.projectInfo, this.clientInfo);

			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			try {
				let pdfFile = await Axios.get(this.pdfFileUrl, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					responseType: 'blob',
				});
				let aTag = this.$refs.downloadTag;
				let url = window.URL.createObjectURL(pdfFile.data);
				aTag.href = url;
				aTag.download = this.defaultFileName;
				aTag.click();
			} catch (error) {
				console.log(error);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		async onAcceptConfirm() {
			this.$store.commit('setIsAppProcessing', true);

			this.$bvModal.show(`${this.plainModalId}`);
			this.plainContents = PLAIN_NOTI_CONTENTS;
			await this._updateConfirmFlag(this.estimateParticularId);

			this.$store.commit('setIsAppProcessing', false);
		},

		async onDirectLink() {
			this.isConfirmMessShow = false;
		},

		async _getPreviewPDFUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get PDF url failed!';
				}

				console.log('%c Get PDF url successfully!', 'color: red');
				let url = response.data.link;
				return url;
			} catch (error) {
				console.log(error);
			}
		},

		onPDFClick() {
			this.pdfPreviewer.url = this.pdfFileUrl;
			this.$bvModal.show(`preview-pdf-modal`);
		},

		/**
		 * Get estimate data
		 * Include: estimateParticular, EstimateDetails and projectName
		 */
		async _getEstimateData() {
			// Check if current project id existed
			if (this.projectId) {
				await this._getEstimateParticularAndChildren(this.projectId);
				await this._getInvoice(this.estimateParticularId);
				// Update issue_date, expiry_date
				this.estimateData.estimateParticular['issue_date'] =
					this.invoiceData['issue_date'];
				this.estimateData.estimateParticular['expiry_date'] =
					this.invoiceData['transfers_deadline'];
				this.estimateData.estimateParticular['bank_name'] =
					this.invoiceData['bank_name'];
				this.estimateData.estimateParticular['bank_branch'] =
					this.invoiceData['bank_branch'];
				this.estimateData.estimateParticular['account_type'] =
					this.invoiceData['account_type'];
				this.estimateData.estimateParticular['account_holder'] =
					this.invoiceData['account_holder'];
				this.estimateData.estimateParticular['account_number'] =
					this.invoiceData['account_number'];
				this.pdfData = { ...this.estimateData };
				this.isShowContent = true;
				await this._getPdfFileUrl(this.pdfFileKey);
			}
		},

		async _getAddCostDetails(quoteId) {
			try {
				let response = await AddCostDetailService.get(quoteId);
				if (response && response.status !== 200) {
					throw 'Get add cost detail failed';
				}
				let addCostDetails = response.data;
				return addCostDetails;
			} catch (error) {
				console.log(`Get add_cost detail failed: ${error}`);
			}
		},

		_getDefaultFileName(projectInfo, clientInfo) {
			let nowDate = new Date();
			let downloadDateTime = moment(nowDate).format('YYYYMMDDHHmmss');
			this.defaultFileName =
				clientInfo['client_name'] +
				'様_' +
				projectInfo['project_name'] +
				'_請求書_' +
				downloadDateTime +
				'.pdf';
		},

		/**
		 * Change confirm flag from 0 to 1
		 * @param {String} quoteId EstimateParticular ID
		 */
		async _updateConfirmFlag(quoteId) {
			console.log('Update confirm flag');
			let response = await InvoiceService.confirm(quoteId);
			if (response && response.status === 200) {
				socketClient.send('settlement_client_confirm', {
					userId: this.userId,
					projectId: this.projectId,
					content: 'Client confirmed',
				});
				return response.data;
			} else {
				// TODO: Error notification
				console.log('Update confirm flag failed');
			}
		},

		async _updateInvoice(quoteId, content) {
			try {
				let response = await InvoiceService.update(quoteId, content);
				if (!response || response.status !== 200) {
					throw 'Update invoie failed';
				}

				console.log('%c Update invoie successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get pdf template file as url
		 * @param {String} pdfFileKey Key of the PDF template file get from pdf_file_key from record
		 */
		async _getPdfFileUrl(pdfFileKey) {
			if (pdfFileKey) {
				this.pdfFileUrl = await this._getPreviewPDFUrl(
					pdfFileKey,
					CommonConst.ONE_WEEK_SECONDS
				);
			}
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} projectId : project id
		 */
		async _getEstimateParticularAndChildren(projectId) {
			try {
				let response = await EstimateParticularService.get(projectId);

				if (response && response.status === 200) {
					console.log('Get estimate particular successfully');
					let data = response.data;
					let estimateDetails = data['estimate_details'].sort((previous, current) => {
						if (previous.id < current.id) return -1;
						if (previous.id > current.id) return 1;
						return 0;
					});
					this.estimateParticularId = data['estimate_particular']['id'];

					let addCostDetails = await this._getAddCostDetails(this.estimateParticularId);
					this.addCostDetails = addCostDetails;

					let projectName = {
						project_name: this.projectInfo.project_name,
					};

					let bankInfo = {
						bank_name: this.clientInfo['bank_name'],
						bank_branch: this.clientInfo['branch_name'],
						account_type: this.clientInfo['account_type'],
						account_holder: this.clientInfo['account_holder'],
						account_number: this.clientInfo['account_number'],
					};

					this.estimateData.estimateParticular = {
						...data['estimate_particular'],
						...projectName,
						...bankInfo,
					};

					this.computeAddCostDetailsMoneyAmount(
						this.managementMasterInfo['consumption_tax_rate'],
						this.addCostDetails
					);

					this.estimateData.estimateDetails = estimateDetails.concat(addCostDetails);
					this.companyName = data['estimate_particular']['company_name'];
				} else {
					this.isQuotationShow = false;
					this.downloadButtonShow = false;
					console.log('Get estimate particular failed');
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		/**
		 * Get Invoice Data
		 * @param {Integer} quoteId EstimateParticular ID
		 * @returns invoiceData
		 */
		async _getInvoice(quoteId) {
			try {
				let response = await InvoiceService.get(quoteId);
				if (!response || response.status !== 200) {
					this.isQuotationShow = false;
					this.downloadButtonShow = false;

					throw 'Get Invoice failed!';
				}

				console.log('%c Get Invoice successfully!', 'color: red');
				this.invoiceData = response.data;
				this.settlementStatus = response.data['status'];
				// TODO: move to settlement
				this.pdfFileKey = response.data['pdf_file_key'];
				// this.isQuotationShow = true;
				if (!this.invoiceData['transfers_deadline']) {
					this.isQuotationShow = false;
					this.downloadButtonShow = false;
					this.noneObjectLabel = 'ご請求書は未作成です。';
				} else {
					this.isQuotationShow = true;
					this.downloadButtonShow = true;
					this.noneObjectLabel = '';
				}
			} catch (error) {
				console.log(error);
			}
		},
		_checkStatus() {
			if (this.settlementStatus === CommonConst.SCREEN_STATUS.SAVE) {
				this.isQuotationShow = false;
				this.downloadButtonShow = false;
			} else if (this.settlementStatus === CommonConst.SCREEN_STATUS.WAITING_PAYMENT) {
				this.isQuotationShow = true;
				this.downloadButtonShow = true;
			} else if (this.settlementStatus === CommonConst.SCREEN_STATUS.PAID) {
				this.isQuotationShow = true;
				this.downloadButtonShow = true;
			}
		},
	},

	async created() {
		//When creator click in Confirm Button
		socketClient.listen('new_settlement_notification', (data) => {
			this.isConfirmMessShow = data;
		});

		//When creator click in Save Button
		socketClient.listen('new_settlement_quotation_content', async (data) => {
			this.$store.commit('setIsAppProcessing', true);

			this.settlementStatus = data;
			await this._getEstimateData(this.projectId);

			this.$store.commit('setIsAppProcessing', false);
		});

		//When settlement paid
		socketClient.listen('new_settlement_paid', async (data) => {
			this.$store.commit('setIsAppProcessing', true);

			this.settlementStatus = data;
			await this._getEstimateData(this.projectId);
			this.$router.push(this.directLink);

			this.$store.commit('setIsAppProcessing', false);
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		// Get Estimate Data to check initial state
		await this._getEstimateData(this.projectId);

		await this._updateInvoice(this.estimateData.estimateParticular.id, {
			status: 'onSeenReceipt',
		});
		this.removeSettlementRedDot();

		this.$store.commit('setIsAppProcessing', false);
	},

	updated() {
		this._checkStatus();
	},

	beforeDestroy() {
		socketClient.removeAllListeners('new_settlement_notification');

		socketClient.removeAllListeners('new_settlement_quotation_content');

		socketClient.removeAllListeners('new_settlement_paid');
	},
};
