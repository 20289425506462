import ApiService from './api.service';

const serviceURL = 'invoice';
const InvoiceService = {
	/**
	 * Acquire Invoice by quoteID
	 * @param {Integer} quoteID EsimateParticular ID
	 * @returns {Object} Schedule details
	 */
	get(quoteID) {
		return ApiService.get(`${serviceURL}`, `${quoteID}`);
	},

	/**
	 * Create new Invoice record
	 * @param {Object} body {doc_content: {Invoice Object}}
	 * @returns {Object} include success message
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update Invoice content
	 * @param {Integer} quoteID: EsimateParticular ID
	 * @param {Object} contents:
	 * @returns
	 */
	update(quoteID, contents) {
		let body = {
			doc_content: contents,
		};
		return ApiService.put(`${serviceURL}/${quoteID}`, body);
	},

	/**
	 * confirm Invoice content
	 * @param {Integer} quoteID: EsimateParticular ID
	 * @returns
	 */
	confirm(quoteID) {
		return ApiService.put(`${serviceURL}/${quoteID}/confirm`);
	},
};

export default InvoiceService;
